/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.yna.co.kr/view/AKR20220830135351017"
  }, "이용자 1천600만 '알약' 오류로 PC 먹통…유저들 \"보상하라\"(종합) - 연합뉴스")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.7-zip.org/"
  }, "7-Zip")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kr.bandisoft.com/bandizip/"
  }, "반디집 공식 홈페이지 · ALZ, EGG, 7Z, RAR 지원 무료 압축 프로그램")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EB%B9%B5%EC%A7%91_(%EC%86%8C%ED%94%84%ED%8A%B8%EC%9B%A8%EC%96%B4)"
  }, "빵집 (소프트웨어) - 위키백과")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://namu.wiki/w/%EC%82%AC%EC%82%AC%EB%AF%B8#s-3"
  }, "사사미 동영상 플레이어 - 나무위키")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://ko.wikipedia.org/wiki/%EA%B3%B0%ED%94%8C%EB%A0%88%EC%9D%B4%EC%96%B4"
  }, "곰플레이어 - 위키백과")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://bitwarden.com/"
  }, "Bitwarden Open Source Password Manager")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2022/8/17/23309796/airbnb-android-users-accidental-test-notification"
  }, "Airbnb accidentally sent tons of Android users a ‘test’ notification - The Verge")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://krausefx.com/blog/announcing-inappbrowsercom-see-what-javascript-commands-get-executed-in-an-in-app-browser"
  }, "iOS Privacy: Announcing InAppBrowser.com - see what JavaScript commands get injected through an in-app browser · Felix Krause")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://fastlane.tools/"
  }, "fastlane - App automation done right")), "\n");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
